import { bindable, observable, computedFrom } from 'aurelia-framework';
import { Security } from 'common/security';
import { Products } from 'services/products';
import { LOB, WIDGET, KPI, UNDERWRITING } from 'common/constants';
import { c } from 'common/common';

export class WidgetSettings {
    static inject = [Element, Security, Products];
    _security;
    _products;

    KPI = KPI;

    @bindable canEdit;
    @bindable displayChartOptions = false;
    @bindable settings;
    @bindable showGroupBy = false;
    @bindable showGroupByCarrier = true;
    @bindable showChartType = false;
    @bindable showWritingAgents = true;
    @bindable showRestrictView = false;
    @bindable showFriendlyName = false;
    @bindable forMetric;

    friendlyName;
    _defaultSync = true;
    @observable({ changeHandler: '_dispatch' }) sync;
    chartTypeOptions;
    _defaultChartType;
    @observable({ changeHandler: '_dispatch' }) chartType;
    viewOptions;
    _defaultView;
    @observable({ changeHandler: '_dispatch' }) view;
    groupByOptions;
    _defaultGroupBy;
    @observable({ changeHandler: '_dispatch' }) restrictToForMemberView = false;
    @observable({ changeHandler: '_dispatch'}) groupBy;
    periodOptions;
    _defaultPeriod;
    @observable({ changeHandler: '_dispatch' }) period;
    metricOptions;
    _defaultMetric;
    @observable({ changeHandler: '_metricChanged' }) metric;
    lineOfBusinessOptions;
    _defaultLinesOfBusiness;
    @observable({ changeHandler: '_linesOfBusinessChanged' }) linesOfBusiness;
    lifeProductTypeOptions;
    _defaultLifeProductTypes;
    lifeProductTypes;
    productUnderwritingOptions;
    _defaultProductUnderwritings;
    productUnderwritings;
    policyStatusOptions;
    _defaultPolicyStatus;
    @observable({ changeHandler: '_dispatch' }) policyStatus;
    premiumTypeOptions;
    _defaultPremiumType;
    @observable({ changeHandler: '_dispatch' }) premiumType;

    constructor(element, security, products) {
        this._element = element;
        this._security = security;
        this._products = products;

        this.chartTypeOptions = WIDGET.ChartType.all();
        this._defaultChartType = WIDGET.ChartType.default();
        this.viewOptions = WIDGET.View.all(security, true);
        this._defaultView = WIDGET.View.default();
        this.groupByOptions = WIDGET.GroupBy.all(security);
        this._defaultGroupBy = WIDGET.GroupBy.default();
        this.periodOptions = WIDGET.Period.all();
        this._defaultPeriod = WIDGET.Period.default();
        this.metricOptions = KPI.Metric.all();
        this._defaultMetric = KPI.Metric.default();
        this.lineOfBusinessOptions = LOB.all();
        this._defaultLinesOfBusiness = LOB.defaultLines();
        this.policyStatusOptions = WIDGET.PolicyStatusGroup.all();
        this._defaultPolicyStatus = WIDGET.PolicyStatusGroup.default();
        this.premiumTypeOptions = KPI.PremiumType.all();
        this._defaultPremiumType = KPI.PremiumType.default();
    }

    async bind() {
        if (!this.showGroupByCarrier) {
            const idx = this.groupByOptions.findIndex(x => x === WIDGET.GroupBy.Carrier);
            if (idx >= 0) this.groupByOptions.splice(idx, 1);
        }
        await this._load();
        this.settingsChanged();
    }

    async _load() {
        try {
            const productTypes = await this._products.typesByLOB();
            const lifeProductTypeOptions = productTypes.find(x => x.lineOfBusiness === LOB.Life).productTypes;
            this.lifeProductTypeOptions = [];
            lifeProductTypeOptions.forEach(o => {
                this.lifeProductTypeOptions.push({ key: o, checked: false });
            })
            this._defaultLifeProductTypes = lifeProductTypeOptions;
            this.productUnderwritingOptions = [];
            const productUnderwritings = UNDERWRITING.all();
            productUnderwritings.forEach(u => {
                this.productUnderwritingOptions.push({ key: u, checked: false });
            });
            this._defaultProductUnderwritings = productUnderwritings;
        } catch (err) {
            console.log(err);
        }
    }

    settingsChanged(newValue, oldValue) {
        if (this._initialized && oldValue && newValue && JSON.stringify(oldValue) === JSON.stringify(newValue)) return;
        try {
            this._initializingSettings = true;
            this.friendlyName = this.settings ? c.Helpers.decodeJsProperty(this.settings.friendlyName) || '' : '';
            this.sync = this.settings ? (this.settings.sync === true || this.settings.sync === false) ? this.settings.sync : this._defaultSync : this._defaultSync;
            this.chartType = this.settings ? this.settings.chartType || this._defaultChartType : this._defaultChartType;
            this.view = this.settings ? this.settings.view || this._defaultView : this._defaultView;
            this.restrictToForMemberViewMemberId = this.settings ? this.settings.restrictToForMemberViewMemberId || undefined : undefined;
            this.restrictToForMemberView = this.restrictToForMemberViewMemberId ? true : false;
            this.groupBy = this.settings ? this.settings.groupBy || this._defaultGroupBy : this._defaultGroupBy;
            this.period = this.settings ? this.settings.period || this._defaultPeriod : this._defaultPeriod;
            if (this.forMetric) {
                this.metric = this.forMetric;
            } else {
                this.metric = this.settings ? this.settings.metric || this._defaultMetric : this._defaultMetric;
            }
            this.linesOfBusiness = this.settings ? this.settings.linesOfBusiness || this._defaultLinesOfBusiness : this._defaultLinesOfBusiness;
            this.lifeProductTypes = this.settings ? this.settings.lifeProductTypes || this._defaultLifeProductTypes : this._defaultLifeProductTypes;
            if (this.lifeProductTypeOptions) {
                this.lifeProductTypeOptions.forEach(o => {
                    o.checked = this.lifeProductTypes.includes(o.key);
                });
            }
            this.productUnderwritings = this.settings ? this.settings.productUnderwritings || this._defaultProductUnderwritings : this._defaultProductUnderwritings;
            if (this.productUnderwritingOptions) {
                this.productUnderwritingOptions.forEach(o => {
                    o.checked = this.productUnderwritings.includes(o.key);
                });
            }
            this.policyStatus = this.settings ? this.settings.policyStatus || this._defaultPolicyStatus : this._defaultPolicyStatus;
            this.premiumType = this.settings ? this.settings.premiumType || this._defaultPremiumType : this._defaultPremiumType;
            this._initializingSettings = false;
            this._dispatch();
        } catch (err) {
            console.log(err);
        } finally {
            this._initialized = true;
        }
    }

    _metricChanged() {
        this._initializingSettings = true;
        if (KPI.requiresPolicyStatus(this.metric) && !this.policyStatus) this.policyStatus = this._defaultPolicyStatus;
        if (KPI.requiresLineOfBusiness(this.metric) && (!this.linesOfBusiness || !this.linesOfBusiness.length)) this.linesOfBusiness = this._defaultLinesOfBusiness;
        if (KPI.requiresPremiumType(this.metric) && !this.premiumType) this.premiumType = this._defaultPremiumType;
        this._initializingSettings = false;
        this._dispatch();
    }

    onFriendlyNameBlur() {
        if (this._initializingSettings) return;
        this._dispatch();
    }

    _linesOfBusinessChanged() {
        if (this.linesOfBusiness.includes(LOB.Life)) {
            this.displayLifeProductTypes = true;    
        } else {
            this.displayLifeProductTypes = false;
        }
        this._dispatch();
    }

    lifeProductTypeSelected(productType) {
        const idx = this.lifeProductTypes.findIndex(x => x === productType.key);
        if (productType.checked) {
            if (idx < 0) this.lifeProductTypes.push(productType.key);
        } else {
            if (idx >= 0) this.lifeProductTypes.splice(idx, 1);
        }
        this._dispatch();
    }

    productUnderwritingSelected(underwriting) {
        const idx = this.productUnderwritings.findIndex(x => x === underwriting.key);
        if (underwriting.checked) {
            if (idx < 0) this.productUnderwritings.push(underwriting.key);
        } else {
            if (idx >= 0) this.productUnderwritings.splice(idx, 1);
        }
        this._dispatch();
    }

    _dispatch() {
        if (this._initializingSettings) return;
        const data = {
            friendlyName: this.friendlyName ? c.Helpers.encodeJsProperty(this.friendlyName) : '',
            sync: (this.sync === true || this.sync === false) ? this.sync : this._defaultSync,
            chartType: this.chartType || this._defaultChartType,
            view: this.view || this._defaultView,
            restrictToForMemberViewMemberId: this.restrictToForMemberViewMemberId,
            groupBy: this.groupBy || this._defaultGroupBy,
            period: this.period || this._defaultPeriod,
            metric: this.metric || this._defaultMetric,
            linesOfBusiness: this.linesOfBusiness || this._defaultLinesOfBusiness,
            lifeProductTypes: this.lifeProductTypes || this._defaultLifeProductTypes,
            productUnderwritings: this.productUnderwritings || this._defaultProductUnderwritings,
            policyStatus: this.policyStatus || this._defaultPolicyStatus,
            premiumType: this.premiumType || this._defaultPremiumType,
        };
        if (!KPI.requiresPolicyStatus(data.metric)) data.policyStatus = null;
        if (!KPI.requiresLineOfBusiness(data.metric)) data.linesOfBusiness = null;
        if (!KPI.requiresPremiumType(data.metric)) data.premiumType = null;
        if (this.canEdit === true && this.showRestrictView === true && this.restrictToForMemberView === true) data.restrictToForMemberViewMemberId = this._security.authenticatedMemberId;
        else if (this.canEdit === true && this.showRestrictView === true && !this.restrictForMemberView) data.restrictToForMemberViewMemberId = null;
        if (this._dispatchTimeout) window.clearTimeout(this._dispatchTimeout);
        this._dispatchTimeout = window.setTimeout(() => {
            this._dispatchEvent(data);
        }, 500);
    }

    _dispatchEvent(data) {
        this._element.dispatchEvent(new CustomEvent('changed', { bubbles: true, detail: { data, save: this._initialized } }));
    }

    @computedFrom('showRestrictView', 'view')
    get showRestrictViewOption() {
        return this.showRestrictView && (this.view === WIDGET.View.Agency || this.view === WIDGET.View.Downline);
    }

    @computedFrom('metric')
    get showPolicyStatus() {
        return KPI.requiresPolicyStatus(this.metric);
    }

    @computedFrom('metric')
    get showLinesOfBusiness() {
        return KPI.requiresLineOfBusiness(this.metric);
    }

    @computedFrom('metric')
    get showPremiumType() {
        return KPI.requiresPremiumType(this.metric);
    }
}
