import moment from 'moment';

export class WidgetBase {
    _element;
    showSettings = false;
    showSettingsDrawer = false;

    constructor(element) {
        this._element = element;
    }

    toggleSettings() {
        this.showSettings = !this.showSettings;
        if (this.showSettings) {
            this.showSettingsDrawer = moment().format();
        } else {
            this.showSettingsDrawer = undefined;
        }
    }

    dispatchSettingsEvent(data, save = false) {
        this._element.dispatchEvent(new CustomEvent('settings', { bubbles: true, detail: { data, save } }));
    }
}
