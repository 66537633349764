import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { WidgetBase } from './../widget-base';

export class WidgetToDos extends WidgetBase {
    static inject = [Element, Security];
    security;

    @bindable settings;

    constructor(element, security) {
        super(element);
        this.security = security;
    }
}
